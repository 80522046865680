export default function useCategoryList() {
  const columns = [
    // { key: 'id', sortable: true },
    { key: 'key', sortable: true },
    { key: 'title', sortable: true },
    { key: 'slug', sortable: true },
    { key: 'upperCategory', sortable: true },
    { key: 'description', sortable: true },
    { key: 'Action', sortable: false },
    // { key: 'stock', sortable: true },
  ];

  return {
    columns,
  };
}
